<template>
  <div class="col-12" >
    <div class="row justify-content-center text-left" v-for="laboralExperience in laboralExperiences" :key="laboralExperience.id">
      <div class="col-12">
        <div class="row align-items-center">
         <div class="col-12">
          <el-card shadow="never" style="margin-bottom:15px;">   
            <div class="row">
                  <div class="col-10 col-sm-12" v-if="laboralExperience.id>0" >
                     <div class="row">
                        <div class="col-2">
                          <img src="@/assets/images/employer64.png" alt="employers"/>
                        </div>
                        <div class="col-10">                          
                            <h3><strong>{{laboralExperience.charge}} </strong></h3>           
                            <hr/>                                   
                            <p class="lead" v-if="laboralExperience.endDate.day!=''" >
                                  <span style="text-align:left;margin-right:5px;"> 
                                        {{ laboralExperience.company }} 
                                  </span>
                                  <span> 
                                        {{ new Date(laboralExperience.startDate).getDate()}}/{{ new Date(laboralExperience.startDate).getMonth()}}/{{ new Date(laboralExperience.startDate).getFullYear()}} -                             
                                        {{ new Date(laboralExperience.endDate).getDate()}}/{{ new Date(laboralExperience.endDate).getMonth()}}/{{new Date(laboralExperience.endDate).getFullYear()}}
                                  </span>                                  
                            </p>
                            <p class="lead" v-else>
                                <span style="text-align:left;margin-right:5px;"> 
                                      {{ laboralExperience.company }}  
                                </span>                                 
                                <span> 
                                      {{ new Date(laboralExperience.startDate).getDate()}}/{{new Date(laboralExperience.startDate).getMonth()}}/{{new Date(laboralExperience.startDate).getFullYear()}} - Actualmente
                                </span> 
                            </p>                                                                           
                        </div>                        
                     </div>
                     <div class="row">
                        <div class="col-12">
                           <hr/>
                           <p>{{ laboralExperience.description}}</p>     
                        </div>                     
                     </div>
                  </div>
                  <div class="col-10 col-sm-12"  v-else>
                     <div class="row">
                        <div class="col-2">
                          <img src="@/assets/images/employer64.png" alt="employers"/>
                        </div>
                        <div class="col-10">
                            <h3><strong>{{laboralExperience.position}} </strong></h3>   
                              <hr/>                      
                            <p class="lead" v-if="laboralExperience.endDate.day!=''" >
                                <span style="text-align:left;"> {{ laboralExperience.company }} </span>
                                <span>  {{ laboralExperience.startDate.day}}/{{  laboralExperience.startDate.month}}/{{ laboralExperience.startDate.year}} - {{ laboralExperience.endDate.day}}/{{ laboralExperience.endDate.month}}/{{laboralExperience.endDate.year}}</span>
                            </p>
                            <p class="lead" v-else>
                                <span>{{ laboralExperience.company }}  </span> 
                                <span> {{  laboralExperience.startDate.day}}/{{laboralExperience.startDate.month}}/{{ laboralExperience.startDate.year}} - Actualmente</span> 
                            </p>                       
                        </div>
                     </div>

                        <hr/>
                        <p>{{ laboralExperience.description}}</p> 
                  </div>                             
                  <div class="col-2 col-sm-12">
                    <el-button  type="primary" plain text="warning" @click="laboralVisible=true">Editar</el-button>
                    <el-button  type="danger" plain text="danger">Eliminar</el-button>
                  </div>
            </div>
          </el-card>
          </div>          
        </div>
      </div>
   </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-md-12">
        <el-dialog v-model="laboralVisible" >
            <template  #header >
            <div class="my-header">
                <h2  class="title" id="exampleModalLabel">Añadir Experiencia</h2>       
            </div>
            </template>
            
            <div class="col-lg-12 col-md-12">
                <AddLaboralExperience/>                
            </div>
                                  

        </el-dialog>        
    </div>
</div>
</template>
 
<script>
import {computed,ref} from 'vue'
import {useStore} from 'vuex'
import AddLaboralExperience from "@/modules/candidates/components/AddLaboralExperience.vue"
export default {
  components:{
    AddLaboralExperience
   },
 setup(){
     
    const store =useStore()
    const laboralVisible=ref(false)
    const laboralExperiences = computed(() => store.getters["candidate/listLaboralExperience"])   
    console.log(laboralExperiences.startDate)
    console.log(laboralExperiences.endDate)

    return {
      laboralExperiences,laboralVisible            
     }
 },
}
</script>

<style  scoped>
  
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto&display=swap');
  
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
.search {
    background-color: #fdfbfc
}
.title{
  color:#0476e0;
}
.search input {
    width: 100px;
    border: none;
    outline: none;
    padding-top: 2px;
    padding-bottom: 2px;
    background-color: #fdfbfc
}

div.btn,
button.btn {
    background-color: #F07F29;
    color: #eee
}

div.btn:hover,
button.btn:hover {
    background-color: #ac1f32d7
}

.navbar-light .navbar-nav .nav-link {
    color: #333
}

nav {
    float: left
}

#language {
    float: right
}

#language select {
    border: none;
    outline: none
}

.wrapper {
    width: 85%;
    margin: 20px auto;
    box-shadow: 1px 1px 30px 10px rgba(250, 250, 250, 0.8)
}

.h3 {
    padding-top: 40px;
    font-size: 34px
}

.h4 {
    padding-top: 40px;
    font-size: 24px
}

label {
    display: block;
    font-size: 0.8rem;
    font-weight: 700
}

input {
    border: none;
    outline: none;
    border-bottom: 2px solid #ddd;
    width: 100%;
    padding-bottom: 10px
}

.wrapper {
    clear: both
}

#province {
    border: none;
    outline: none;
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 2px solid #ddd
}

.wrapper .col-md-6:hover label {
    color: #F07F29
}

.wrapper .col-md-6:hover input,
.wrapper .col-md-6:hover #country {
    border-color: #F07F29;
    cursor: pointer
}

.wrapper .col-md-6 input:focus {
    border-color: #F07F29
}

.option {
    position: relative;
    padding-left: 30px;
    display: block;
    cursor: pointer;
    color: #888
}

.option input {
    display: none
}

.checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%
}

.option input:checked~.checkmark:after {
    display: block
}

.option .checkmark:after {
    content: "\2713";
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 200ms ease-in-out 0s
}

.option:hover input[type="radio"]~.checkmark {
    background-color: #f4f4f4
}

.option input[type="radio"]:checked~.checkmark {
    background: #F07F29;
    color: #fff;
    transition: 300ms ease-in-out 0s
}

.option input[type="radio"]:checked~.checkmark:after {
    transform: translate(-50%, -50%) scale(1);
    color: #fff
}



</style>