<template>
<div class="row">
<form   @submit.prevent="onSubmit">    
    <div class="wrapper d-flex justify-content-center flex-column px-md-12 px-1">
                <div class="row my-md-12 my-2">                    
                    <div class="col-md-12 pt-md-0 pt-4"> <label>Estudios</label> <select name="estudies" id="estudies" v-model="educationExperience.level" required>
                            <option value="1">Educación Secundaria Obligatoria </option>
                            <option value="2">Bachillerato </option>
                            <option value="3">Ciclo formativo Grado Medio </option>
                            <option value="4">Ciclo formativo Grado Superior </option>
                            <option value="5">Enseñanza artisticas (reglada) </option>
                            <option value="6">Enseñanzas deportivas </option>
                            <option value="7">Grado </option>
                            <option value="8">Licenciatura </option>
                            <option value="9">Diplomatura </option>
                            <option value="10">Ingenieria Técnica </option>
                            <option value="11">Ingenieria Superior </option>
                            <option value="12">Postgrado </option>
                            <option value="13">Master </option>
                            <option value="14">Doctorado </option>
                            <option value="15">Otros títulos, Certificaciones, Carnés </option>
                            <option value="16">Otros cursos y formación no reglada </option>
                            <option value="17">Formación profesional Grado Medio </option>
                            <option value="18">Formación profesional Grado Superior </option>
                        </select> 
                    </div>                    
                </div>
        <div class="row my-md-4 my-2">
               <div class="col-lg-12 col-md-12"> <label>Título o Certificado</label> <input type="text" placeholder="Titulo o Certificado" v-model="educationExperience.estudie" required> </div>
        </div>  
        <div class="row my-md-4 my-2">
            <div class="col-md-6"> <label>Fecha Inicio</label> <input type="text" placeholder="DD" style="width:15%;" v-model="educationExperience.startDate.day" maxlength="2" minlength="2" required>| 
                                                               <input type="text" placeholder="MM" style="width:15%;" v-model="educationExperience.startDate.month" maxlength="2" minlength="2" required>|
                                                               <input type="text" placeholder="YYYY" style="width:25%;" v-model="educationExperience.startDate.year" maxlength="4" minlength="4" required> </div>
            <div class="col-md-6"> <label>Fecha Final</label> <input type="text" placeholder="DD" style="width:15%;" v-model="educationExperience.endDate.day" maxlength="2" minlength="2">| 
                                                              <input type="text" placeholder="MM" style="width:15%;" v-model="educationExperience.endDate.month" maxlength="2" minlength="2">|
                                                              <input type="text" placeholder="YYYY" style="width:25%;" v-model="educationExperience.endDate.year" maxlength="4" minlength="4"> </div>
        </div>      
        <div class="row my-md-3  my-2">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="educationExperience.actualy" style="width:18px;height:18px;"/>
                  
        </div>
        <div class="row my-4">
            <div class="col-lg-12 col-md-12"> <label>Centro (opcional)</label> <input type="text" placeholder="Centro Educativo" v-model="educationExperience.center"> </div>
        </div>  
    </div>
    <div class="row my-4">
        <div class="col-lg-12 col-md-12" v-show="validKO"> 
            <el-alert :title="messageKO" type="error" show-icon />         
        </div>
        <div class="col-lg-12 col-md-12" v-show="validOK">   
            <el-alert :title="messageOK" type="success" show-icon />    
        </div>
    </div>   
    <div class="row">
            <div class="col-lg-4 col-md-12"><div class="d-flex justify-content-center"> <button class="primary"  @click="addEducation">Añadir</button> </div></div>
            <div class="col-lg-4 col-md-12"></div>
            <div class="col-lg-4 col-md-12"><div class="d-flex justify-content-center"> <button  type="button" class="btn" @click="newEducation">Nueva</button> </div></div>       
    </div>
</form>
</div>

</template>

<script>
import { ref} from 'vue'
import useCandidate from '@/modules/candidates/composables/useCandidate'
export default {
    name:'AddEducation',
    props:{},
    setup(){
          const {addEducation,educationExperience,clearEducation,messageKO,messageOK,validKO,validOK}=useCandidate()
           const show=ref(false)
          const result=ref(false)
         
            
            return {
                educationExperience,
                messageKO,messageOK,validKO,validOK,
                newEducation:()=>{
                   clearEducation()
                },                
                clearEducation,
                  onSubmit :() =>{ 
                      console.log("Objeto education "+educationExperience)                      
                    const { ok,id }= addEducation()
                    console.log("ok "+ok)
                    console.log("id "+id)
                    show.value=true
                    result.value=ok   
                    console.log(result.value)
                    
                }                                            
           
            }
    },
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto&display=swap');


.search {
    background-color: #fdfbfc
}
.title{
  color:#F07F29;
}
.search input {
    width: 100px;
    border: none;
    outline: none;
    padding-top: 2px;
    padding-bottom: 2px;
    background-color: #fdfbfc
}

div.btn,
button.btn {
    background-color: #F07F29;
    color: #eee
}

div.btn:hover,
button.btn:hover {
    background-color: #ac1f32d7
}

.navbar-light .navbar-nav .nav-link {
    color: #333
}

nav {
    float: left
}

#language {
    float: right
}

#language select {
    border: none;
    outline: none
}

.wrapper {
    width: 85%;
    margin: 20px auto;
    box-shadow: 1px 1px 30px 10px rgba(250, 250, 250, 0.8)
}

.h3 {
    padding-top: 40px;
    font-size: 34px
}

label {
    display: block;
    font-size: 0.8rem;
    font-weight: 700
}

input {
    border: none;
    outline: none;
    border-bottom: 2px solid #ddd;
    width: 100%;
    padding-bottom: 10px
}

.wrapper {
    clear: both
}

#estudies {
    border: none;
    outline: none;
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 2px solid #ddd
}

.wrapper .col-md-12:hover label {
    color: #F07F29
}
.wrapper .col-md-12:hover label {
    color: #F07F29
}
.wrapper .col-md-12:hover input,
.wrapper .col-md-12:hover #titulo {
    border-color: #F07F29;
    cursor: pointer
}

.wrapper .col-md-12 input:focus {
    border-color: #F07F29
}

.option {
    position: relative;
    padding-left: 30px;
    display: block;
    cursor: pointer;
    color: #888
}

.option input {
    display: none
}

.checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%
}

.option input:checked~.checkmark:after {
    display: block
}

.option .checkmark:after {
    content: "\2713";
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 200ms ease-in-out 0s
}

.option:hover input[type="radio"]~.checkmark {
    background-color: #f4f4f4
}

.option input[type="radio"]:checked~.checkmark {
    background: #F07F29;
    color: #fff;
    transition: 300ms ease-in-out 0s
}

.option input[type="radio"]:checked~.checkmark:after {
    transform: translate(-50%, -50%) scale(1);
    color: #fff
}


</style>