import {ref} from 'vue'
import {useStore} from 'vuex'
 import setescaApi from "@/modules/api/setescaApi"

 const useMaster = () =>{

    const store =useStore()
    const provinces=ref([])
    const categories=ref([])
    const contractType=ref([])
    const salaryType=ref([])

    function getProvinces()
    {
        try{
            console.log(process.env.NODE_ENV )           
            setescaApi.get("master/provinces").then(response=>{                
               provinces.value=response.data
                console.log(provinces)
            })                        
            console.log("provincias")         
            
        }catch(error)
        {
            console.log("ERROR :"+error)
        }

    }

    function getContractTypes()
    {
        try{                          
            setescaApi.get("master/contractType").then(response=>{
                contractType.value=response.data
            })
        }catch(error)
        {
            console.log("ERROR "+error)
        }               
    }

    function getSalaryTypes()
    {
        try{                          
            setescaApi.get("master/salaryType").then(response=>{
                salaryType.value=response.data
            })
        }catch(error)
        {
            console.log("ERROR "+error)
        }               
    }
    
    
    function getCategories()
    {
        try{                          
            setescaApi.get("master/categories").then(response=>{
                categories.value=response.data
            })
        }catch(error)
        {
            console.log("ERROR "+error)
        }
    }

   
    return{
        getProvinces,
        getSalaryTypes,
        getCategories,
        getContractTypes,
        provinces,
        categories,
        contractType,
        salaryType
    }
 }
 export default useMaster