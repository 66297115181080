<template>
    <div class="row">
    <form   @submit.prevent="onSubmit">    
        <div class="wrapper d-flex justify-content-center flex-column px-md-12 px-1">
            <div class="row my-md-4 my-2">
                    <div class="col-lg-12 col-md-12 pt-md-0 pt-4" style="text-align:left;">
                        <label>Estudios</label> <select name="languages" id="languages" v-model="language.language" required>
                            <option value="0" selected>Seleccione idioma</option>
                            <option value="aleman">Alemán</option>
                            <option value="español">Español </option>
                            <option value="frances">Frances </option>
                            <option value="ingles">Ingles </option>
                            <option value="italiano">Italiano </option>
                            <option value="catalan">Catalan</option>
                            <option value="euskera">Euskera </option>
                            <option value="gallego">Gallego </option>
                            <option value="portuges">Portugues</option>
                            <option value="ruso">Ruso</option>                            
                        </select> 
                    </div> 
            </div>
            <div class="row my-md-4 my-2">
                    <div class="col-lg-6 col-md-12 pt-md-0 pt-4">
                        <label>Nivel</label> <select name="level" id="level" v-model="language.level">
                            <option value="0" selected>Seleccione Nivel</option>
                            <option value="1">Bajo</option>
                            <option value="2">Medio </option>
                            <option value="3">Medio - Alto </option>
                            <option value="4">Alto </option>                         
                        </select> 
                    </div>     
                    <div class="col-lg-6 col-md-12 pt-md-0 pt-4">
                        <label>Nivel Hablado</label> <select name="levelSpoken" id="levelSpoken" v-model="language.level_Spoken">
                            <option value="0" selected>Seleccione Nivel</option>
                            <option value="1">Bajo</option>
                            <option value="2">Medio </option>
                            <option value="3">Medio - Alto </option>
                            <option value="4">Alto </option>                         
                        </select> 
                    </div>                                    
            </div>
            <div class="row my-md-4 my-2">
                    <div class="col-lg-6 col-md-12 pt-md-0 pt-4">
                        <label>Nivel Lectura</label> <select name="levelRead" id="levelRead" v-model="language.level_Read">
                            <option value="0" selected>Seleccione Nivel</option>
                            <option value="1">Bajo</option>
                            <option value="2">Medio </option>
                            <option value="3">Medio - Alto </option>
                            <option value="4">Alto </option>                         
                        </select> 
                    </div> 
                    <div class="col-lg-6 col-md-12 pt-md-0 pt-4">
                        <label>Nivel Escritura</label> <select name="levelWritten" id="levelWritten" v-model="language.level_Written">
                            <option value="0" selected>Seleccione Nivel</option>
                            <option value="1">Bajo</option>
                            <option value="2">Medio </option>
                            <option value="3">Medio - Alto </option>
                            <option value="4">Alto </option>                         
                        </select> 
                    </div>                    
            </div>
            <div class="row my-md-4 my-2">
                    
            </div>                                 
        </div>                
            <div class="row my-4">
                <div class="col-lg-12 col-md-12" v-show="validKO"> 
                    <el-alert :title="messageKO" type="error" show-icon />         
                </div>
                <div class="col-lg-12 col-md-12" v-show="validOK">   
                    <el-alert :title="messageOK" type="success" show-icon />    
                </div>
            </div>  
            <div class="row">
                <div class="col-lg-4 col-md-6"><div class="d-flex justify-content-center"> <button class="btn" @click="addLanguage">Añadir</button> </div></div>
                
                <div class="col-lg-4 col-md-6"><div class="d-flex justify-content-center"> <button  type="button" class="btn" @click="newLanguage">Nueva</button> </div></div>     
           
            </div>
    </form>
    </div>
    
    </template>
    
    <script>
    import { ref} from 'vue'
    import {useStore} from 'vuex'
    import useCandidate from '@/modules/candidates/composables/useCandidate'
    export default {
        name:'AddLanguaje',      
        setup(){
              const {clearLanguage,addLanguage,language,getLanguage,messageKO,messageOK,validKO,validOK}=useCandidate()
              console.log("objeto de la vista")
              console.log(language)  
              clearLanguage()
              return {                   
                newLanguage:()=>{
                  clearLanguage()            
                },                
                addLanguage,
                language,
                getLanguage
                ,messageKO,messageOK,validKO,validOK          
            }
        },
    }
    </script>
    <style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto&display=swap');
    
    
    .search {
        background-color: #fdfbfc
    }
    .title{
      color:#F07F29;
    }
    .search input {
        width: 100px;
        border: none;
        outline: none;
        padding-top: 2px;
        padding-bottom: 2px;
        background-color: #fdfbfc
    }
    
    div.btn,
    button.btn {
        background-color: #F07F29;
        color: #eee
    }
    
    div.btn:hover,
    button.btn:hover {
        background-color: #ac1f32d7
    }
    
    .navbar-light .navbar-nav .nav-link {
        color: #333
    }
    
    nav {
        float: left
    }
    
    
    .wrapper {
        width: 85%;
        margin: 20px auto;
        box-shadow: 1px 1px 30px 10px rgba(250, 250, 250, 0.8)
    }
    
    .h3 {
        padding-top: 40px;
        font-size: 34px
    }
    
    label {
        display: block;
        font-size: 0.8rem;
        font-weight: 700
    }
    
    input {
        border: none;
        outline: none;
        border-bottom: 2px solid #ddd;
        width: 100%;
        padding-bottom: 10px
    }
    
    .wrapper {
        clear: both
    }
    
    #languages {
        border: none;
        outline: none;
        width: 100%;
        padding-bottom: 12px;
        border-bottom: 2px solid #ddd
    }
    
    .wrapper .col-md-12:hover label {
        color: #F07F29
    }
    .wrapper .col-md-12:hover label {
        color: #F07F29
    }
    .wrapper .col-md-12:hover input,
    .wrapper .col-md-12:hover #titulo {
        border-color: #F07F29;
        cursor: pointer
    }
    
    .wrapper .col-md-12 input:focus {
        border-color: #F07F29
    }
    
    .option {
        position: relative;
        padding-left: 30px;
        display: block;
        cursor: pointer;
        color: #888
    }
    
    .option input {
        display: none
    }
    
    .checkmark {
        position: absolute;
        top: -1px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%
    }
    
    .option input:checked~.checkmark:after {
        display: block
    }
    
    .option .checkmark:after {
        content: "\2713";
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: 200ms ease-in-out 0s
    }
    
    .option:hover input[type="radio"]~.checkmark {
        background-color: #f4f4f4
    }
    
    .option input[type="radio"]:checked~.checkmark {
        background: #F07F29;
        color: #fff;
        transition: 300ms ease-in-out 0s
    }
    
    .option input[type="radio"]:checked~.checkmark:after {
        transform: translate(-50%, -50%) scale(1);
        color: #fff
    }
    
    #levelSpoken {
        border: none;
        outline: none;
        width: 100%;
        padding-bottom: 12px;
        border-bottom: 2px solid #ddd
    }
    #levelWritten {
        border: none;
        outline: none;
        width: 100%;
        padding-bottom: 12px;
        border-bottom: 2px solid #ddd
    }
    #levelRead {
        border: none;
        outline: none;
        width: 100%;
        padding-bottom: 12px;
        border-bottom: 2px solid #ddd
    }
    #level {
        border: none;
        outline: none;
        width: 100%;
        padding-bottom: 12px;
        border-bottom: 2px solid #ddd
    }
    </style>