<template>
<div class="container-fluid">
     <form   @submit.prevent="onSubmit">
            <div class="wrapper d-flex justify-content-center flex-column px-md-12 px-1">
                <div class="row my-md-12 my-2">                    
                    <div class="col-lg-12 col-md-12"> <label>Cargo</label> <input type="text" placeholder="Cargo" v-model="laboralExperience.position" required> </div>
                </div>
                <div class="row my-md-12 my-2">                    
                    <div class="col-lg-12 col-md-12"> <label>Empresa</label> <input type="text" placeholder="Empresa" v-model="laboralExperience.company" required> </div>
                </div>                             
            </div> 
            <div class="row my-md-4 my-2">
                <div class="col-md-6"> <label>Fecha Inicio</label> 
                                    <input type="text" placeholder="DD" style="width:15%;" v-model="laboralExperience.startDate.day" maxlength="2" minlength="2" required>| 
                                    <input type="text" placeholder="MM" style="width:15%;" v-model="laboralExperience.startDate.month" maxlength="2" minlength="2" required>|
                                    <input type="text" placeholder="YYYY" style="width:25%;" v-model="laboralExperience.startDate.year" maxlength="4" minlength="4" required> 
                </div>
                <div class="col-md-6"> <label>Fecha Final</label>  
                                    <input type="text" placeholder="DD" style="width:15%;" v-model="laboralExperience.endDate.day" maxlength="2" minlength="2">|
                                    <input type="text" placeholder="MM" style="width:15%;" v-model="laboralExperience.endDate.month" maxlength="2" minlength="2">|
                                    <input type="text" placeholder="YYYY" style="width:25%;" v-model="laboralExperience.endDate.year" maxlength="4" minlength="4"> 
                </div>
            </div>
            <div  class="row my-md-12 my-2">
               
                <el-checkbox v-model="laboralExperience.actualy" label="Actualmente" size="large" />
            </div>
            <div class="row my-4">
                <div class="col-lg-12 col-md-12"> <label>Descripción (opcional)</label> <textarea id="description" name="description" rows="4" cols="50" v-model="laboralExperience.description"/> </div>
            </div>  
            <div class="row my-4" v-show="show">
                <div class="col-lg-12 col-md-12" v-show="resultOK">  <div class="alert alert-success" role="alert"> Experiencia laboral agregada correctamente </div></div>
                <div class="col-lg-12 col-md-12" v-show="resultKO"> <div class="alert alert-danger" role="alert"> Se ha producido un error al agregar la experiencia laboral </div></div>
            </div>  
            <div class="row">
                <div class="col-lg-4 col-md-6 d-inline" ><div class="d-flex justify-content-center"> <el-button size="large" class="primary"  plain type="submit primery" style="width:150xp;" @click="save">Añadir</el-button></div></div>
                
                <div class="col-lg-4 col-md-6 d-inline" ><div class="d-flex justify-content-center"> <el-button  size="large" plain type="primary"  @click="newLaboralExperience">Nueva</el-button> </div></div>
            </div>
     </form>
</div> 


</template>

<script>
import { ref} from 'vue'
import useCandidate from '@/modules/candidates/composables/useCandidate'
export default {
    name:'AddLaboralExperience',
    props:{},
    setup(){
          const {addLaboralExperience,laboralExperience,clearLaboralExperience}=useCandidate()
          const show=ref(false)
          const resultOK=ref(false)
          const resultKO=ref(false)
          function save(){                        
                        addLaboralExperience(laboralExperience.value)                         
                        show.value=true
                        resultOK.value=true
                        resultKO.value=false 
                                
                      
                }             
         return {
                laboralExperience,
                newLaboralExperience:()=>{
                    resultOK.value=false
                    resultKO.value=false 
                   clearLaboralExperience()
                },
                show,
                resultOK,
                resultKO,           
               save                        
           
            }
    },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto&display=swap');


.search {
    background-color: #fdfbfc
}
.title{
  color:#F07F29;
}
.search input {
    width: 100px;
    border: none;
    outline: none;
    padding-top: 2px;
    padding-bottom: 2px;
    background-color: #fdfbfc
}

div.btn,
button.btn {
    background-color: #F07F29;
    color: #eee
}

div.btn:hover,
button.btn:hover {
    background-color: #ac1f32d7
}

.navbar-light .navbar-nav .nav-link {
    color: #333
}

nav {
    float: left
}

#language {
    float: right
}

#language select {
    border: none;
    outline: none
}

.wrapper {
    width: 85%;
    margin: 20px auto;
    box-shadow: 1px 1px 30px 10px rgba(250, 250, 250, 0.8)
}

.h3 {
    padding-top: 40px;
    font-size: 34px
}

label {
    display: block;
    font-size: 0.8rem;
    font-weight: 700
}

input {
    border: none;
    outline: none;
    border-bottom: 2px solid #ddd;
    width: 100%;
    padding-bottom: 10px
}

textarea{
        border: none;
    outline: none;
    border-bottom: 2px solid #ddd;
    width: 100%;
    padding-bottom: 10px
}
.wrapper {
    clear: both
}

#province {
    border: none;
    outline: none;
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 2px solid #ddd
}

.wrapper .col-md-6:hover label {
    color: #F07F29
}

.wrapper .col-md-6:hover input,
.wrapper .col-md-6:hover #country {
    border-color: #F07F29;
    cursor: pointer
}

.wrapper .col-md-6 input:focus {
    border-color: #F07F29
}

.option {
    position: relative;
    padding-left: 30px;
    display: block;
    cursor: pointer;
    color: #888
}

.option input {
    display: none
}

.checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%
}

.option input:checked~.checkmark:after {
    display: block
}

.option .checkmark:after {
    content: "\2713";
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 200ms ease-in-out 0s
}

.option:hover input[type="radio"]~.checkmark {
    background-color: #f4f4f4
}

.option input[type="radio"]:checked~.checkmark {
    background: #F07F29;
    color: #fff;
    transition: 300ms ease-in-out 0s
}

.option input[type="radio"]:checked~.checkmark:after {
    transform: translate(-50%, -50%) scale(1);
    color: #fff
}


</style>