<template>
  <div class="col-12">
    <div class="row justify-content-center text-left" v-for="education in educations" :key="education.id">
      <div class="col-12">
        <div class="row align-items-center">
         <div class="col-12">
          <el-card shadow="never" style="margin-bottom:15px;">
              <div class="row">
                <div class="col-10" v-if="education.id>0">
                  <div class="row">
                    <div class="col-2">
                      <img src="@/assets/images/mortarboard64.png" alt="educations"/>                   
                    </div>
                    <div class="col-10">
                      <h3><strong>{{education.estudie}} </strong></h3>
                      <hr/>   
                      <p class="lead"><strong> </strong>{{level[education.level-1].text}}</p>
                      <p class="lead"><strong> </strong> {{ education.center }}</p>
                      <p class="lead"  v-if="education.endDate!=''"><strong>  {{ new Date(education.startDate).getDate()}}/{{ new Date(education.startDate).getMonth() + 1}}/{{ new Date(education.startDate).getFullYear()}}</strong>   -   
                                                                    <strong>{{ new Date(education.endDate).getDate()}}/{{ new Date(education.endDate).getMonth() + 1 }}/{{ new Date(education.endDate).getFullYear()}}</strong></p>
                      <p class="lead" v-else><strong></strong>  {{ new Date(education.startDate).getDate()}}/{{ new Date(education.startDate).getMonth()+1}}/{{ new Date(education.startDate).getFullYear()}}-  Actualmente</p>
                      <p class="lead">{{ education.description}}</p>                         
                    </div>                    
                  </div>
                 
                </div>
                <div class="col-10" v-else>
                  <div class="row">
                    <div class="col-2">
                      <img src="@/assets/images/mortarboard64.png" alt="educations"/>                     
                    </div>
                    <div class="col-10">
                      <h3><strong>{{education.estudie}} </strong></h3>
                      <hr/> 
                      <p class="lead"><strong> </strong>{{level[education.level-1].text}}</p>
                      <p class="lead"><strong> </strong> {{ education.center }}</p>
                      <p class="lead"  v-if="education.endDate.day!=''"><strong></strong>  {{ education.startDate.day}}/{{education.startDate.month}}/{{education.startDate.year}} <strong> </strong>{{ education.endDate.day}}/{{education.endDate.year}}/{{education.endDate.year}}</p>
                      <p class="lead" v-else><strong></strong>  {{ education.startDate.day}}/{{education.startDate.month}}/{{education.startDate.year}} -  Actualmente</p>
                      <p class="lead">{{ education.description}}</p>                         
                    </div>                    
                  </div>
                 
                </div>                
                <div class="col-2 col-sm-12">
                  <el-button  type="primary" plain text="warning">Editar</el-button>
                  <el-button  type="danger" plain text="danger">Eliminar</el-button>
                </div>
              </div>          
          
          </el-card>                           
          </div>
          </div>
        </div>
      </div>
   </div>
</template>
 
<script>
import {ref,computed} from 'vue'
import {useStore} from 'vuex'
//import useCandidate from '@/modules/candidates/composables/useCandidate'
export default {
 setup(){     
         const store =useStore()         
         const educations = computed(() => store.getters["candidate/listEducation"])
         console.log(educations)
         const level=ref([
                            {'value':'1', 'text':'Educación Secundaria Obligatoria'},
                            {'value':'2', 'text':'Bachillerato'},
                            {'value':'3', 'text':'Ciclo formativo Grado Medio'},
                            {'value':'4', 'text':'Ciclo formativo Grado Superior'},
                            {'value':'5', 'text':'Enseñanza artisticas (reglada)'},
                            {'value':'6', 'text':'Enseñanzas deportivas'},
                            {'value':'7', 'text':'Grado'},
                            {'value':'8', 'text':'Licenciatura'},
                            {'value':'9', 'text':'Diplomatura'},
                            {'value':'10', 'text':'Ingenieria Técnica'},
                            {'value':'11', 'text':'Ingenieria Superior'},
                            {'value':'12', 'text':'Postgrado'},
                            {'value':'13', 'text':'Master'},
                            {'value':'14', 'text':'Doctorado'},
                            {'value':'15', 'text':'Otros títulos, Certificaciones, Carnés'},
                            {'value':'16', 'text':'Otros cursos y formación no reglada'},
                            {'value':'17', 'text':'Formación profesional Grado Medio'},
                            {'value':'18', 'text':'Formación profesional Grado Superior'}
         ])
        return {
            educations,
            level         
        }
 },
}
</script>

<style  scoped>
  


</style>