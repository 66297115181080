<template>
    <div class="col-12">
      <div class="row justify-content-center text-left" v-for="(languageExperience,index) in languageExperiences" :key="languageExperience.id">
        <div class="col-12">
          <div class="row align-items-center">
           <div class="col-12">
            <el-card shadow="never" style="margin-bottom:15px;">
                  <div class="row">
                    <div class="col-10">
                      <div class="row">
                        <div class="col-2">
                          <img src="@/assets/images/icons8-global-64.png" alt="langugaes"/>
                        </div>
                        <div class="col-10">
                          <h3><strong>{{languageExperience.language}} </strong></h3>
                          <hr/>
                          <p class="lead">Nivel           : {{languageExperience.level}} - {{vLanguageExperience[languageExperience.level-1]}}</p>
                          <p class="lead">Nivel Lectura   : {{languageExperience.level_Read}} - {{vLanguageExperience[languageExperience.level_Read-1]}}</p>
                          <p class="lead">Nivel Escritura : {{languageExperience.level_Written}} - {{vLanguageExperience[languageExperience.level_Written-1]}}</p>
                          <p class="lead">Nivel Hablado   : {{languageExperience.level_Spoken}} - {{vLanguageExperience[languageExperience.level_Spoken-1]}}</p>                          
                        </div>
                      </div>
                    </div>
                    <div class="col-1">
                      <el-button  type="primary" text="primary" plain @click="edit(index)">Editar</el-button>
                    </div>
                    <div class="col-1">
                      <el-button  type="danger" text="danger" plain @click="edit(index)">Eliminar</el-button>   
                    </div>
                  </div>            
            </el-card>                                                          
            </div>          
          </div>
        </div>
     </div>
    </div>
    <div class="col-lg-12 col-md-12">
      <div class="row">
    <div class="col-lg-6 col-md-12">
        <el-dialog v-model="laboralVisible" >
            <template  #header >
            <div class="my-header">
                <h2  class="title" id="exampleModalLabel">Añadir Experiencia</h2>       
            </div>
            </template>
            <div class="col-lg-3 col-md-12">
                <br/>
            </div>
            <div class="col-lg-6 col-md-12">
                <AddLaboralExperience/>                
            </div>
            <div class="col-lg-3 col-md-12">
                <br/>
            </div>                        

        </el-dialog>        
    </div>
</div>

<div class="row">
    <div class="col-lg-6 col-md-12">
        <el-dialog v-model="languajeVisible" >
            <template  #header >
            <div class="my-header">
                <h2  class="title" id="exampleModalLabel">Añadir Idiomas</h2>       
            </div>
            </template>
            <div class="col-lg-3 col-md-12">
                <br/>
            </div>
            <div class="col-lg-6 col-md-12">
                <AddLanguageExperience/>                
            </div>
            <div class="col-lg-3 col-md-12">
                <br/>
            </div>                        

        </el-dialog>        
    </div>
</div>

<div class="row">
    <div class="col-lg-6 col-md-12">
        <el-dialog v-model="estudiesVisible" >
            <template  #header >
            <div class="my-header">
                <h2  class="title" id="exampleModalLabel">Añadir Idiomas</h2>       
            </div>
            <div class="my-header">
                <hr/>       
            </div>            
            </template>
            <div class="col-lg-3 col-md-12">
                <br/>
            </div>
            <div class="col-lg-6 col-md-12">
                <AddLanguageExperience />                
            </div>
            <div class="col-lg-3 col-md-12">
                <br/>
            </div>                        

        </el-dialog>        
    </div>
</div>      
    </div>
  </template>
   
  <script>
  import {computed,ref} from 'vue'
  import {useStore} from 'vuex'
  import useCandidate from '@/modules/candidates/composables/useCandidate'
  import AddLanguageExperience from "@/modules/candidates/components/AddLanguagesExperience.vue"
  export default {
    components:{
     AddLanguageExperience
 },    
   setup(){
       
         const estudiesVisible=ref(false)
         const {editLanguage}=useCandidate()
         const store =useStore()
         const vLanguageExperience=ref(['Bajo','Medio','Medio - Alto','Alto'])
         const languageExperiences = computed(() => store.getters["candidate/listLanguageExperience"])       
         function edit(index)
         {
            console.log(index)
            estudiesVisible.value = true
            console.log(estudiesVisible.value)
            editLanguage(index)
         }
        return {
          languageExperiences,vLanguageExperience,editLanguage,edit,estudiesVisible
            
        }
   },
  }
  </script>
  
  <style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto&display=swap');
    
    
    .search {
        background-color: #fdfbfc
    }
    .title{
      color:#F07F29;
    }
    .search input {
        width: 100px;
        border: none;
        outline: none;
        padding-top: 2px;
        padding-bottom: 2px;
        background-color: #fdfbfc
    }
    
    div.btn,
    button.btn {
        background-color: #F07F29;
        color: #eee
    }
    
    div.btn:hover,
    button.btn:hover {
        background-color: #ac1f32d7
    }
    
    .navbar-light .navbar-nav .nav-link {
        color: #333
    }
    
    nav {
        float: left
    }
    
    
    .wrapper {
        width: 85%;
        margin: 20px auto;
        box-shadow: 1px 1px 30px 10px rgba(250, 250, 250, 0.8)
    }
    
    .h3 {
        padding-top: 40px;
        font-size: 34px
    }
    
    label {
        display: block;
        font-size: 0.8rem;
        font-weight: 700
    }
    
    input {
        border: none;
        outline: none;
        border-bottom: 2px solid #ddd;
        width: 100%;
        padding-bottom: 10px
    }
    
    .wrapper {
        clear: both
    }
    
    #languages {
        border: none;
        outline: none;
        width: 100%;
        padding-bottom: 12px;
        border-bottom: 2px solid #ddd
    }
    
    .wrapper .col-md-12:hover label {
        color: #F07F29
    }
    .wrapper .col-md-12:hover label {
        color: #F07F29
    }
    .wrapper .col-md-12:hover input,
    .wrapper .col-md-12:hover #titulo {
        border-color: #F07F29;
        cursor: pointer
    }
    
    .wrapper .col-md-12 input:focus {
        border-color: #F07F29
    }
    
    .option {
        position: relative;
        padding-left: 30px;
        display: block;
        cursor: pointer;
        color: #888
    }
    
    .option input {
        display: none
    }
    
    .checkmark {
        position: absolute;
        top: -1px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%
    }
    
    .option input:checked~.checkmark:after {
        display: block
    }
    
    .option .checkmark:after {
        content: "\2713";
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: 200ms ease-in-out 0s
    }
    
    .option:hover input[type="radio"]~.checkmark {
        background-color: #f4f4f4
    }
    
    .option input[type="radio"]:checked~.checkmark {
        background: #F07F29;
        color: #fff;
        transition: 300ms ease-in-out 0s
    }
    
    .option input[type="radio"]:checked~.checkmark:after {
        transform: translate(-50%, -50%) scale(1);
        color: #fff
    }
    
    #levelSpoken {
        border: none;
        outline: none;
        width: 100%;
        padding-bottom: 12px;
        border-bottom: 2px solid #ddd
    }
    #levelWritten {
        border: none;
        outline: none;
        width: 100%;
        padding-bottom: 12px;
        border-bottom: 2px solid #ddd
    }
    #levelRead {
        border: none;
        outline: none;
        width: 100%;
        padding-bottom: 12px;
        border-bottom: 2px solid #ddd
    }
    #level {
        border: none;
        outline: none;
        width: 100%;
        padding-bottom: 12px;
        border-bottom: 2px solid #ddd
    }
    </style>